import classNames from "classnames";
import { Icon, IconProps } from "../Icon";
import styles from "./IconFrame.module.css";

export const IconFrame = ({
  icon,
  iconSize = "M",
  className,
}: {
  icon: IconProps["name"];
  iconSize?: IconProps["size"];
  className?: string;
}) => {
  return (
    <div className={classNames(styles.frame, className)}>
      <Icon name={icon} size={iconSize} />
    </div>
  );
};
