// This is generated by `yarn generate-icons`.
import { default as bag } from './components/bag'
import { default as barcode } from './components/barcode'
import { default as checkmarkbadgefill } from './components/checkmark-badge-fill'
import { default as checkmarkcircle } from './components/checkmark-circle'
import { default as checkmark } from './components/checkmark'
import { default as chevrondown } from './components/chevron-down'
import { default as chevronup } from './components/chevron-up'
import { default as discountbadge } from './components/discount-badge'
import { default as errorcircle } from './components/error-circle'
import { default as healthhands } from './components/health-hands'
import { default as informationcircle } from './components/information-circle'
import { default as medicalscalpel } from './components/medical-scalpel'
import { default as medication } from './components/medication'
import { default as scissors } from './components/scissors'
import { default as stethoscope } from './components/stethoscope'
import { default as syringe } from './components/syringe'
import { default as teeth } from './components/teeth'
import { default as vitamins } from './components/vitamins'
    
export const glyphs = {
  "bag": bag,
  "barcode": barcode,
  "checkmark-badge-fill": checkmarkbadgefill,
  "checkmark-circle": checkmarkcircle,
  "checkmark": checkmark,
  "chevron-down": chevrondown,
  "chevron-up": chevronup,
  "discount-badge": discountbadge,
  "error-circle": errorcircle,
  "health-hands": healthhands,
  "information-circle": informationcircle,
  "medical-scalpel": medicalscalpel,
  "medication": medication,
  "scissors": scissors,
  "stethoscope": stethoscope,
  "syringe": syringe,
  "teeth": teeth,
  "vitamins": vitamins,
}